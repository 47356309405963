import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { PackagingMethodService } from 'services/packaging-method-service';

@autoinject
export class PackagingMethodNew {
  private packagingmethod: Models.PackagingMethod = new Models.PackagingMethod();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private packagingMethodService: PackagingMethodService
  ) {}

  private createPackagingMethod() {
    this.packagingMethodService
      .post(this.packagingmethod)
      .then((res) => {
        this.eventAggregator.publish('packagingMethodListReset', 1);
        this.toastService.showSuccess('packagingmethod.created');
        this.router.navigateToRoute('packaging-method-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
