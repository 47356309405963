import { DeleteDialogService } from 'services/delete-dialog-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { PreparationRopeKnotService } from 'services/preparation-rope-knot-service';

@autoinject
export class PreparationRopeKnotDetail {
  private preparationropeknot: Models.PreparationRopeKnot;

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private preparationRopeKnotService: PreparationRopeKnotService,
    private deleteDialogService: DeleteDialogService
  ) {}

  private activate(params) {
    this.preparationRopeKnotService
      .get(params.Id)
      .then( (res) => {
        this.preparationropeknot = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updatePreparationRopeKnot() {
    this.preparationRopeKnotService
      .put(this.preparationropeknot, this.preparationropeknot.Id)
      .then((res) => {
        this.eventAggregator.publish('preparationRopeKnotListReset', 1);
        this.toastService.showSuccess('preparationropeknot.updated');
        this.router.navigateToRoute('preparation-rope-knot-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deletePreparationRopeKnot() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.preparationRopeKnotService
          .delete(this.preparationropeknot.Id)
          .then((res) => {
            this.eventAggregator.publish('preparationRopeKnotListReset', 1);
            this.toastService.showSuccess('preparationropeknot.deleted');
            this.router.navigateToRoute('preparation-rope-knot-list');
          })
          .catch((err) => this.errorService.handleError(err));
        }
      );    
  }

  private gotoParentView() {
    this.eventAggregator.publish('preparationRopeKnotListReset', 1);
    this.router.navigateToRoute('preparation-rope-knot-list');
  }
}
