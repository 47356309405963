import { DeleteDialogService } from 'services/delete-dialog-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { PackagingMethodService } from 'services/packaging-method-service';

@autoinject
export class PackagingMethodDetail {
  private packagingmethod: Models.PackagingMethod;

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private packagingMethodService: PackagingMethodService,
    private deleteDialogService: DeleteDialogService
  ) {}

  private activate(params) {
    this.packagingMethodService
      .get(params.Id)
      .then( (res) => {
        this.packagingmethod = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updatePackagingMethod() {
    this.packagingMethodService
      .put(this.packagingmethod, this.packagingmethod.Id)
      .then((res) => {
        this.eventAggregator.publish('packagingMethodListReset', 1);
        this.toastService.showSuccess('packagingmethod.updated');
        this.router.navigateToRoute('packaging-method-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deletePackagingMethod() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {  
        this.packagingMethodService
          .delete(this.packagingmethod.Id)
          .then((res) => {
            this.eventAggregator.publish('packagingMethodListReset', 1);
            this.toastService.showSuccess('packagingmethod.deleted');
            this.router.navigateToRoute('packaging-method-list');
          })
          .catch((err) => this.errorService.handleError(err));      
        }
      ); 
  }

  private gotoParentView() {
    this.eventAggregator.publish('packagingMethodListReset', 1);
    this.router.navigateToRoute('packaging-method-list');
  }
}
