import { PriceCalculationDataService } from 'services/price-calculation-data-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class PriceCalculationDataDetail {
  private pricecalculationdata: Models.PriceCalculationData;

  constructor(
    private priceCalculationDataService: PriceCalculationDataService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator
  ) {}

  activate(params) {
    this.priceCalculationDataService
      .get(1)
      .then(pricecalculationdata => {        
        this.pricecalculationdata = pricecalculationdata;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updatePriceCalculationData() {
    this.priceCalculationDataService
      .put(this.pricecalculationdata, this.pricecalculationdata.Id)
      .then(res => {        
        this.toastService.showSuccess("pricecalculationdata.updated");        
        this.gotoParentView();
      })
      .catch(err => this.errorService.handleError(err));
  }
  
  gotoParentView(){    
    this.router.navigateToRoute("admin-overview");   
  }
}
