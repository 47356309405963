import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { PreparationRopePlacementService } from 'services/preparation-rope-placement-service';

@autoinject
export class PreparationRopePlacementNew {
  private preparationropeplacement: Models.PreparationRopePlacement = new Models.PreparationRopePlacement();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private preparationRopePlacementService: PreparationRopePlacementService
  ) {}

  private createPreparationRopePlacement() {
    this.preparationRopePlacementService
      .post(this.preparationropeplacement)
      .then((res) => {
        this.eventAggregator.publish('preparationRopePlacementListReset', 1);
        this.toastService.showSuccess('preparationropeplacement.created');
        this.router.navigateToRoute('preparation-rope-placement-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
