import { DeleteDialogService } from 'services/delete-dialog-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ProducerService } from 'services/producer-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class ProducerDetail {
  private producer: Models.Producer;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private producerService: ProducerService,
    private router: Router,
    private toastService: ToastService,
    private validationController: ValidationController,
    private validator: Validator,
    private deleteDialogService: DeleteDialogService
  ) {

  }

  private activate(params) {
    this.producerService
      .get(params.Id)
      .then((producer) => {
        this.producer = producer;
        this.activateValidationRules();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private activateValidationRules() {
    ValidationRules
      .ensure('Name').required().withMessage(this.i18n.tr('general.requiredField'))
      .ensure('Email').required().withMessage(this.i18n.tr('general.requiredField'))
      .ensure('Email').email().withMessage(this.i18n.tr('general.incorrectEmailFormat'))
      .on(this.producer);
  }

  private updateProducer() {
    this.producerService
      .put(this.producer, this.producer.Id)
      .then((res) => {
        this.eventAggregator.publish('producerListReset', 1);
        this.toastService.showSuccess('producer.updated');
        this.router.navigateToRoute('producer-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteProducer() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.producerService
          .delete(this.producer.Id)
          .then((res) => {
            this.eventAggregator.publish('producerListReset', 1);
            this.toastService.showSuccess('producer.deleted');
            this.router.navigateToRoute('producer-list');
          })
          .catch((err) => this.errorService.handleError(err));
      }
    ); 
  }
  
  private gotoParentView() {
    this.eventAggregator.publish('producerListReset', 1);
    this.router.navigateToRoute('producer-list');
  }

  private validateBeforeSave() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.producer)
      .then((result) => {
        const errors = result.filter((validateResult) => {
          return !validateResult.valid;
        });
        if (errors.length > 0) {
          this.validationController.validate();
        } else {
          this.updateProducer();
        }
      });
  }

}
