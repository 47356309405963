import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { PlacementService } from "services/placement-service";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class PlacementNew {
  private placement: Models.Placement = new Models.Placement();

  constructor(
    private placementService: PlacementService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createPlacement() {
    this.placementService
      .post(this.placement)
      .then(res => {
        this.eventAggregator.publish("placementListReset", 1);
        this.toastService.showSuccess("placement.created");
        this.router.navigateToRoute("placement-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
