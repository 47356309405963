import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, bindingMode, containerless } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { PreparationRopeKnotPlacementService } from 'services/preparation-rope-knot-placement-service';
import { PreparationRopeKnotService } from 'services/preparation-rope-knot-service';
import { PreparationRopePlacementService } from 'services/preparation-rope-placement-service';
import { PreparationRopeService } from 'services/preparation-rope-service';
import { PreparationRopeTypeService } from 'services/preparation-rope-type-service';
import { RopeDimensionService } from 'services/rope-dimension-service';
import { RopeTypeService } from 'services/rope-type-service';
import { ToastService } from 'services/toast-service';

@containerless
@autoinject
export class PreparationRopeFormInlineEdit {
  @bindable private preparationrope: Models.PreparationRope;
  private currentRopeType: Models.RopeType;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private preparationRopeKnotPlacementService: PreparationRopeKnotPlacementService,
    private preparationRopeKnotService: PreparationRopeKnotService,
    private preparationRopePlacementService: PreparationRopePlacementService,
    private preparationRopeService: PreparationRopeService,
    private preparationRopeTypeService: PreparationRopeTypeService,
    private ropeDimensionService: RopeDimensionService,
    private ropeTypeService: RopeTypeService,
    private router: Router,
    private toastService: ToastService
  ) { }

  private updatePreparationRope() {

    this.preparationrope.RopeType = null;
    this.preparationrope.PreparationRopeType = null;
    this.preparationrope.RopeDimension = null;
    this.preparationrope.PreparationRopePlacement = null;
    this.preparationrope.PreparationRopeKnot1 = null;
    this.preparationrope.PreparationRopeKnot2 = null;
    this.preparationrope.PreparationRopeKnotPlacement1 = null;
    this.preparationrope.PreparationRopeKnotPlacement2 = null;

    this.preparationRopeService
      .put(this.preparationrope, this.preparationrope.Id)
      .then((res) => {
        this.eventAggregator.publish('preparationRopeListReset', 1);
        this.eventAggregator.publish('preparationRopeFormEditClose', 1);
        this.toastService.showSuccess('preparationrope.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getRopeTypes() {
    return this.ropeTypeService.getAllCached()
        .then((res) => {
          return res.filter((x) => x.NavisionProductId || x.DeltaProductId);
        });
  }

  private ropeTypeChanged(event) {
    if (!event.detail.value || event.detail.value === this.preparationrope.RopeTypeId) {
      return;
    }
    this.preparationrope.RopeTypeId = event.detail.value;
    this.ropeTypeService
      .get(this.preparationrope.RopeTypeId)
      .then((res) => {
        this.currentRopeType = res;
        this.preparationrope.RopeDimensionId = this.currentRopeType.RopeDimensionId;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private cancelEdit() {
    this.eventAggregator.publish('preparationRopeListReset', 1);
  }

}
