import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { PreparationRopeKnotService } from 'services/preparation-rope-knot-service';

@autoinject
export class PreparationRopeKnotNew {
  private preparationropeknot: Models.PreparationRopeKnot = new Models.PreparationRopeKnot();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private preparationRopeKnotService: PreparationRopeKnotService
  ) {}

  private createPreparationRopeKnot() {
    this.preparationRopeKnotService
      .post(this.preparationropeknot)
      .then((res) => {
        this.eventAggregator.publish('preparationRopeKnotListReset', 1);
        this.toastService.showSuccess('preparationropeknot.created');
        this.router.navigateToRoute('preparation-rope-knot-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
