import { DeleteDialogService } from 'services/delete-dialog-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { PreparationRopePlacementService } from 'services/preparation-rope-placement-service';

@autoinject
export class PreparationRopePlacementDetail {
  private preparationropeplacement: Models.PreparationRopePlacement;

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private preparationRopePlacementService: PreparationRopePlacementService,
    private deleteDialogService: DeleteDialogService
  ) {}

  private activate(params) {
    this.preparationRopePlacementService
      .get(params.Id)
      .then( (res) => {
        this.preparationropeplacement = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updatePreparationRopePlacement() {
    this.preparationRopePlacementService
      .put(this.preparationropeplacement, this.preparationropeplacement.Id)
      .then((res) => {
        this.eventAggregator.publish('preparationRopePlacementListReset', 1);
        this.toastService.showSuccess('preparationropeplacement.updated');
        this.router.navigateToRoute('preparation-rope-placement-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deletePreparationRopePlacement() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.preparationRopePlacementService
          .delete(this.preparationropeplacement.Id)
          .then((res) => {
            this.eventAggregator.publish('preparationRopePlacementListReset', 1);
            this.toastService.showSuccess('preparationropeplacement.deleted');
            this.router.navigateToRoute('preparation-rope-placement-list');
          })
          .catch((err) => this.errorService.handleError(err));
        }
      ); 
  }

  private gotoParentView() {
    this.eventAggregator.publish('preparationRopePlacementListReset', 1);
    this.router.navigateToRoute('preparation-rope-placement-list');
  }
}
