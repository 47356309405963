import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { PackagingTypeService } from 'services/packaging-type-service';

@autoinject
export class PackagingTypeList {
  private tableData: Array<Models.PackagingType>;
  private activeTableRow: number;

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private packagingTypeService: PackagingTypeService
  ) {}

  private activate(params) {
    if (params.Id) {
      this.activeTableRow = params.Id;
    } else {
      this.activeTableRow = 0;
    }
  }

  private attached(params) {
    this.eventAggregator.subscribe('packagingTypeListReset', (value) => {
      this.activeTableRow = null;
      return this.updateView();
    });
    this.updateView();
  }

  private updateView() {
    this.packagingTypeService
      .getAll()
      .then((res) => {
        this.tableData = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private gotoDetailView(id) {
    this.activeTableRow = id;
    this.router.navigateToRoute('packaging-type-detail', { Id: id });
  }
}
