import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { PackagingTypeService } from 'services/packaging-type-service';

@autoinject
export class PackagingTypeNew {
  private packagingtype: Models.PackagingType = new Models.PackagingType();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private packagingTypeService: PackagingTypeService
  ) {}

  private createPackagingType() {
    this.packagingTypeService
      .post(this.packagingtype)
      .then((res) => {
        this.eventAggregator.publish('packagingTypeListReset', 1);
        this.toastService.showSuccess('packagingtype.created');
        this.router.navigateToRoute('packaging-type-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
