import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { PlacementService } from "services/placement-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class placementDetail {
  private placement: Models.Placement;

  constructor(
    private placementService: PlacementService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.placementService
      .get(params.Id)
      .then(placement => {
        this.placement = placement;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updatePlacement() {
    this.placementService
      .put(this.placement, this.placement.Id)
      .then(res => {
        this.eventAggregator.publish("placementListReset", 1);
        this.toastService.showSuccess("placement.updated");
        this.router.navigateToRoute("placement-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deletePlacement() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.placementService
          .delete(this.placement.Id)
          .then(res => {
            this.eventAggregator.publish("placementListReset", 1);
            this.toastService.showSuccess("placement.deleted");
            this.router.navigateToRoute("placement-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      ); 
  }
  
  gotoParentView(){
    this.eventAggregator.publish("placementListReset", 1);
    this.router.navigateToRoute("placement-list");   
  }
}
