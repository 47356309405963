import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ProducerService } from 'services/producer-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class ProducerNew {
  private producer: Models.Producer = new Models.Producer();
  private saving: boolean = false;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private producerService: ProducerService,
    private router: Router,
    private toastService: ToastService,
    private validationController: ValidationController,
    private validator: Validator
  ) {
    ValidationRules
      .ensure('Name').required().withMessage(this.i18n.tr('general.requiredField'))
      .ensure('Email').required().withMessage(this.i18n.tr('general.requiredField'))
      .ensure('Email').email().withMessage(this.i18n.tr('general.incorrectEmailFormat'))
      .on(this.producer);
  }

  private createProducer() {
    this.saving = true;
    this.producerService
      .post(this.producer)
      .then((res) => {
        this.eventAggregator.publish('producerListReset', 1);
        this.toastService.showSuccess('producer.created');
        this.router.navigateToRoute('producer-detail', { Id: res.Id });
        this.saving = false;
      })
      .catch((err) => {
        this.saving = false;
        this.errorService.handleError(err);
      });
  }

  private validateBeforeSave() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.producer)
      .then((result) => {
        const errors = result.filter((validateResult) => {
          return !validateResult.valid;
        });
        if (errors.length > 0) {
          this.validationController.validate();
        } else {
          this.createProducer();
        }
      });
  }

}
