import { Models } from "models/core";
import { bindable } from "aurelia-framework";

export class ProducerForm {
  @bindable producer: Models.Producer;

  constructor() {}

  public setImageId(imageIdToUpdate: string, event: any) {
    this.producer[imageIdToUpdate] = event.detail.id;
  }

  public deleteImageId(imageIdToUpdate: string) {
    this.producer[imageIdToUpdate] = null;
  }
}
